import React from "react"
import { Helmet } from "react-helmet"

const NotFound = () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Gasefi</title>
      <meta http-equiv="cache-control" content="max-age=0" />
      <meta http-equiv="expires" content="Tue, 01 Jan 1980 1:00:00 GMT" />
      <meta http-equiv="pragma" content="no-cache" />
    </Helmet>
    <div
      style={{
        display: "flex",
        width: "100vw",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <p style={{ color: "var(--clr-grey)" }}>Page Not Found</p>
    </div>
  </>
)

export default NotFound
